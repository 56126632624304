<template>
    <div>
        <v-row>
            <div
                class="d-flex mb-6 mt-3"
                v-for="(insight, index) of insights"
                :key="index"
            >
                <v-card
                    class="pt-5 px-2 ma-2"
                    min-width="250px"
                    max-width="250px"
                >
                    <v-card-text
                        class="d-flex my-0 py-0 mx-0 px-0"
                        width="100%"
                    >
                        <v-chip
                            class="mb-1 mt-0 mx-2 pt-0 white--text"
                            :color="insight.color"
                            label
                            style="height: 50px"
                        >
                            <v-icon center>
                                {{ insight.icon }}
                            </v-icon>
                        </v-chip>
                        <div class="mb-1 mt-0 pt-0">
                            <div class="d-flex mr-0 pr-0 text-capitalize">
                                <span>{{ insight.title }}</span>
                            </div>
                            <p class="text-h6 black--text">
                                {{ insight.value }}
                            </p>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </v-row>
        <v-data-table
            :headers="quoteHeaders"
            :items="formattedForecasts"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="9"><h2 class="ml-4">QUOTES</h2> </v-col>

                    <v-spacer />
                    <v-col cols="3" class="">
                        <v-btn-toggle
                            style="width: 100%;"
                            v-if="!quoteInEdition"
                        >
                            <v-btn
                                @click.stop="showYears()"
                                rounded
                                small
                                style="width: 50%"
                                >YEARS</v-btn
                            >
                            <v-btn
                                @click.stop="showMonths()"
                                rounded
                                small
                                style="width: 50%"
                                >MONTHS</v-btn
                            >
                        </v-btn-toggle>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <td :class="['fixed-column', 'white-background']" v-if="item">
                    <v-menu rounded offset-y>
                        <template v-slot:activator="{ attrs, on }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                depressed
                                class="ml-2"
                                :disabled="
                                    quoteInEdition && item.id != quoteInEdition
                                "
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item
                                v-if="
                                    item.id != quoteInEdition &&
                                        user.id == project.projectManager
                                "
                                @click.stop="openReplaceForecast(item)"
                            >
                                <v-list-item-icon>
                                    <v-icon class="mr-1">mdi-sync</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Replace Quote Forecast
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.id != quoteInEdition"
                                @click.stop="openHistory(item)"
                            >
                                <v-list-item-icon>
                                    <v-icon class="mr-1">mdi-book</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Forecast History
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.id == quoteInEdition"
                                @click="openAddNewMonth(item)"
                            >
                                <v-list-item-icon>
                                    <v-icon class="mr-1"
                                        >mdi-plus-circle</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Add New Month
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="
                                    item.id == quoteInEdition &&
                                        diffConditions()
                                "
                                @click="replaceForecast()"
                                :disabled="
                                    calculateForecastedPercentage(item) > 100
                                "
                            >
                                <v-list-item-icon>
                                    <v-icon class="mr-1"
                                        >mdi-content-save</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Update Forecast
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.id == quoteInEdition"
                                @click="closeReplaceForecast(item)"
                            >
                                <v-list-item-icon>
                                    <v-icon class="mr-1"
                                        >mdi-close-thick</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Cancel Update
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </td>
            </template>
            <template v-slot:[`item.quoteNumber`]="{ item }">
                <div class="my-4">
                    <p>
                        {{ item.number }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.quoteName`]="{ item }">
                <div class="my-4">
                    <p>
                        {{ item.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.quotePrice`]="{ item }">
                <div class="my-4">
                    <p class="d-flex justify-end">
                        {{ formatCurrency(project.currency, item.totalCosts) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.balanceToBill`]="{ item }">
                <div class="my-4">
                    <p class="d-flex justify-end">
                        {{
                            formatCurrency(project.currency, item.balanceToBill)
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.baseBalanceToBill`]="{ item }">
                <div class="my-4">
                    <p class="d-flex justify-end">
                        {{
                            item.baseBalanceToBill
                                ? formatCurrency(
                                      project.currency,
                                      item.baseBalanceToBill
                                  )
                                : '-'
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.invoicedThisMonth`]="{ item }">
                <div class="my-4">
                    <p class="d-flex justify-end">
                        {{
                            formatCurrency(
                                project.currency,
                                Number(item.invoicedThisMonth)
                            ) || 0
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.invoicedThisYear`]="{ item }">
                <div class="my-4">
                    <p class="d-flex justify-end">
                        {{
                            formatCurrency(
                                project.currency,
                                item.invoicedThisYear
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.lastUpdate`]="{ item }">
                {{
                    item.updatedOn
                        ? formatDate(
                              item.updatedOn
                                  ? item.updatedOn._seconds
                                  : item.createdOn._seconds
                          )
                        : '-'
                }}
            </template>
            <template
                v-slot:[`item.actualVsForecasted`]="{
                    item,
                }"
            >
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize d-flex justify-end">
                        {{ getCompliancePercentage(item) }}
                        %
                    </p>
                </div>
            </template>
            <template v-slot:[`item.forecastedPercentage`]="{ item }">
                <div class="d-flex justify-center">
                    <p
                        class="my-0 text-capitalize"
                        :class="{
                            'text-orange':
                                calculateForecastedPercentage(item) < 100,
                            'text-green':
                                calculateForecastedPercentage(item) == 100,
                            'text-red':
                                calculateForecastedPercentage(item) > 100,
                        }"
                    >
                        {{ calculateForecastedPercentage(item) }}%
                    </p>
                </div>
            </template>
            <template v-slot:[`item.date`]="{ item, header }">
                <v-col
                    v-if="
                        (item[header.month] &&
                            item[header.month].percentage > 0) ||
                            quoteInEdition == item.id
                    "
                >
                    <v-row class="d-flex justify-center"
                        ><p class="my-1">
                            {{ getDatePercentage(item, header.month) }}
                            %
                        </p>
                        <v-tooltip
                            right
                            class="d-flex justify-center ml-1"
                            v-if="
                                ((item[header.month] &&
                                    item[header.month].editable) ||
                                    !item[header.month]) &&
                                    quoteInEdition == item.id &&
                                    header.month != currentDate
                            "
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    small
                                    icon
                                    class="d-flex justify-center mt-0 ml-0"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="
                                        openEditMonth(
                                            item[header.month],
                                            header.month
                                        )
                                    "
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Forecast</span>
                        </v-tooltip></v-row
                    >
                    <v-row class="d-flex justify-center"
                        ><p class="mb-1">
                            {{
                                formatCurrency(
                                    project.currency,
                                    getDateAmount(item, header.month)
                                )
                            }}
                        </p></v-row
                    >
                </v-col>
            </template>
            <template v-slot:[`item.dateYear`]="{ item, header }">
                <v-col>
                    <p class="my-1">
                        {{ getDatePercentage(item, header.year) }}
                        %
                    </p>
                    <p class="mb-1">
                        {{
                            formatCurrency(
                                project.currency,
                                getDateAmount(item, header.year)
                            )
                        }}
                    </p>
                </v-col>
            </template>
            <template v-slot:[`body.append`]>
                <tr style="background-color: #eeeeee">
                    <td class="font-weight-bold" />
                    <td class="font-weight-bold">
                        TOTALS
                    </td>
                    <td class="font-weight-bold" />
                    <td class="font-weight-bold">
                        <p class="d-flex justify-end align-center my-0">
                            {{
                                formatCurrency(project.currency, project.price)
                            }}
                        </p>
                    </td>
                    <td class="font-weight-bold">
                        <p
                            class="d-flex justify-end align-center my-0"
                            style="width: 100%;"
                        >
                            {{
                                formatCurrency(
                                    project.currency,
                                    project.price - (project.totalInvoiced || 0)
                                )
                            }}
                        </p>
                    </td>
                    <td class="font-weight-bold">
                        <p
                            class="d-flex justify-end align-center my-0"
                            style="width: 100%;"
                        >
                            {{
                                formatCurrency(project.currency, totalBaseB2B())
                            }}
                        </p>
                    </td>
                    <td
                        class="font-weight-bold d-flex justify-end align-center"
                    >
                        {{ formatCurrency(project.currency, totalInDate()) }}
                    </td>
                    <td class="font-weight-bold"></td>
                    <td
                        class="font-weight-bold d-flex align-center justify-center"
                    >
                        {{ insights[3].value }}
                    </td>

                    <td
                        class="my-0"
                        v-for="(header, index) in quoteHeaders.slice(
                            9,
                            quoteHeaders.length - 1
                        )"
                        :key="index"
                    >
                        <p
                            class="d-flex justify-center align-center my-0"
                            style="text-align: center;"
                        >
                            {{ totalPerDate(currentDate) }}
                        </p>
                    </td>
                    <td :class="['fixed-column', 'gray-background']" />
                </tr>
            </template>
        </v-data-table>
        <!--ForecastForm-->
        <v-dialog persistent max-width="2000px" v-model="historyView">
            <ForecastHistory
                v-if="historyView"
                :historyQuote="historyQuote"
                :project="project"
                :invoices="invoices"
                @closeDialog="closeHistoryView"
            />
        </v-dialog>
        <!--ForecastForm-->
        <v-dialog persistent max-width="300px" v-model="newMonthForm">
            <ForecastEntryForm
                v-if="newMonthForm"
                :createDialog="createDialog"
                :originalEntry="originalEntry"
                :dates="dateToShow"
                :forecast="forecast"
                :quote="selectedForecast"
                @addEntry="addEntry"
                @replaceEntry="replaceEntry"
                @closeDialog="newMonthForm = false"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff'

export default {
    name: 'B2BForecast',
    props: {
        projectId: {
            type: String,
            default: () => '',
        },
    },
    components: {
        ForecastHistory: () =>
            import('@/components/Forecast/ForecastHistory.vue'),
        ForecastEntryForm: () =>
            import('@/components/Forecast/ForecastEntryForm.vue'),
    },
    data: () => ({
        loading: true,
        companyId: JSON.parse(localStorage.getItem('company')),
        insights: [
            {
                value: 0,
                color: '#3498DB',
                iconColor: '#1A5276',
                icon: 'mdi-currency-usd',
                title: 'Project Price',
            },
            {
                value: 0,
                color: '#27658f',
                iconColor: '#1A5276',
                icon: 'mdi-invoice-text-check-outline',
                title: 'Project Billed',
            },
            {
                value: 0,
                color: '#19405c',
                iconColor: '#1A5276',
                icon: 'mdi-cash-clock',
                title: 'Balance To Bill',
            },
            {
                value: 0,
                color: '#0b1c28',
                iconColor: '#1A5276',
                icon: 'mdi-percent-outline',
                title: 'Forecasted Percentage',
            },
        ],
        quoteHeaders: [],
        originalQuoteHeaders: [],
        months: [
            'JAN',
            'FEB',
            'MAR',
            'APR',
            'MAY',
            'JUN',
            'JUL',
            'AUG',
            'SEP',
            'OCT',
            'NOV',
            'DEC',
        ],
        viewType: 'month',
        forecastDialog: false,
        createDialog: undefined,
        selectedForecast: {},
        dateToShow: [],
        forecast: [],
        quoteInEdition: undefined,
        newMonthForm: false,
        originalEntry: {},
        quotes: [],
        invoices: {},
        invoicedPerQuote: {},
        originalForecast: null,
        currentDate: '',
        project: {},
        historyView: false,
        historyQuote: {},
        historyInvoices: null,
        allDates: [],
        user: {},
    }),
    computed: {
        formattedForecasts() {
            const quotes = this.getFormattedForecasts()
            return quotes
        },
        forecastDiff: function() {
            const newForecast = this.formattedForecasts.find(
                quote => quote.id == this.quoteInEdition
            )
            delete newForecast.updatedOn
            if (newForecast) {
                return objDiff(this.selectedForecast, newForecast)
            } else {
                return []
            }
        },
    },
    watch: {},
    async mounted() {
        try {
            this.loading = true

            let currentDate = new Date()
            this.currentDate = `${currentDate.getFullYear()}-${String(
                currentDate.getMonth() + 1
            ).padStart(2, '0')}`
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.project = await API.getProject(this.projectId)
            this.forecast = this.project.forecast || []

            await this.getQuotesData()
            await this.getInvoices()

            this.setHeaders()
            this.getInsights()
            this.showMonths()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        diffConditions() {
            return Object.keys(this.forecastDiff).length > 0
        },
        async getQuotesData() {
            try {
                this.quotes = await API.getProjectQuotes(this.project.id)
                this.quotes = this.quotes.map(quote => {
                    return {
                        costExchanges: quote.costExchanges,
                        currency: quote.currency,
                        exchange: quote.exchange,
                        id: quote.id,
                        name: quote.name,
                        number: quote.number,
                        totalCosts: quote.totalCosts,
                    }
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async getInvoices() {
            let invoices = await API.getInvoices({
                projectId: this.project.id,
            })
            invoices = invoices.filter(
                invoice =>
                    invoice.status != 'rejected' &&
                    invoice.status != 'inProgress' &&
                    invoice.billedOn
            )
            invoices.forEach(invoice => {
                invoice.date = this.formatDate2(invoice.billedOn._seconds)
            })

            this.invoices = {}
            this.invoicedPerQuote = {}

            invoices.forEach(invoice => {
                //Create invoices object with dates as keys
                if (Object.keys(this.invoices).includes(invoice.date)) {
                    this.invoices[invoice.date].push(invoice)
                } else {
                    this.invoices[invoice.date] = [invoice]
                }

                Object.keys(invoice.items).forEach(quoteId => {
                    //Calculate invoiced per quote
                    const invoiced = invoice.items[quoteId].reduce(
                        (accumulator, item) =>
                            accumulator +
                            (item.billedQty - (item.creditQty || 0)) *
                                item.pricePerUnit,
                        0
                    )
                    if (this.invoicedPerQuote[quoteId]) {
                        this.invoicedPerQuote[quoteId].total += invoiced
                    } else {
                        this.invoicedPerQuote[quoteId] = {
                            total: invoiced,
                            thisMonth: 0,
                            thisYear: 0,
                        }
                    }
                })
            })
            //Calculate invoiced per quote in current month
            if (this.invoices[this.currentDate]) {
                this.invoices[this.currentDate].forEach(invoice => {
                    Object.keys(invoice.items).forEach(quoteId => {
                        const invoicedInMonth = invoice.items[quoteId].reduce(
                            (accumulator, item) =>
                                accumulator +
                                (Number(item.billedQty) -
                                    (Number(item.creditQty) || 0)) *
                                    Number(item.pricePerUnit),
                            0
                        )
                        if (this.invoicedPerQuote[quoteId]) {
                            this.invoicedPerQuote[quoteId].thisMonth += Number(
                                invoicedInMonth || 0
                            )
                        } else {
                            this.invoicedPerQuote[quoteId] = {
                                thisMonth: Number(invoicedInMonth || 0),
                            }
                        }
                    })
                })
            }
            // Calculate invoiced per quote in current year
            Object.keys(this.invoices).forEach(date => {
                if (date.split('-')[0] == this.currentDate.split('-')[0]) {
                    this.invoices[date].forEach(invoice => {
                        Object.keys(invoice.items).forEach(quoteId => {
                            const invoicedInYear = invoice.items[
                                quoteId
                            ].reduce(
                                (accumulator, item) =>
                                    accumulator +
                                    (Number(item.billedQty) -
                                        Number(item.creditQty || 0)) *
                                        Number(item.pricePerUnit),
                                0
                            )
                            if (this.invoicedPerQuote[quoteId]) {
                                this.invoicedPerQuote[
                                    quoteId
                                ].thisYear += Number(invoicedInYear || 0)
                            } else {
                                this.invoicedPerQuote[quoteId] = {
                                    thisYear: invoicedInYear || 0,
                                }
                            }
                        })
                    })
                }
            })
        },
        getDatePercentage(item, date) {
            const quote = this.forecast.find(quote => quote.id == item.id)
            if (quote && quote.forecast) {
                if (date.includes('-')) {
                    const amount = this.getDateAmount(item, date)
                    return ((amount * 100) / quote.balanceToBill).toFixed(1)
                } else {
                    const amount = this.getDateAmount(item, date)
                    return ((amount * 100) / quote.balanceToBill).toFixed(1)
                }
            }
        },
        getDateAmount(item, date) {
            const quote = this.forecast.find(quote => quote.id == item.id)
            let entry = null
            if (quote && quote.forecast) {
                if (date.includes('-')) {
                    entry = quote.forecast.find(entry => entry.date == date)
                    return entry ? Number(entry.amount) : 0
                } else {
                    let amount = 0
                    quote.forecast.forEach(entry => {
                        if (
                            entry.date &&
                            entry.amount &&
                            moment(entry.date).isSameOrAfter(
                                this.currentDate
                            ) &&
                            entry.date.includes(date)
                        ) {
                            amount += Number(entry.amount)
                        }
                    })
                    return amount.toFixed(1)
                }
            }
        },
        getCompliancePercentage(quote) {
            if (quote[this.currentDate]?.amount) {
                return (
                    (quote.invoicedThisMonth * 100) /
                    quote[this.currentDate].amount
                ).toFixed(1)
            } else {
                return '-'
            }
        },
        totalInDate() {
            let total = 0
            Object.keys(this.invoicedPerQuote).forEach(quoteId => {
                total +=
                    this.viewType == 'month'
                        ? this.invoicedPerQuote[quoteId]?.thisMonth
                        : this.invoicedPerQuote[quoteId]?.thisYear
            })
            return total
        },
        totalBaseB2B() {
            return this.forecast.reduce(
                (accumulator, entry) =>
                    accumulator + (entry.balanceToBill || 0),
                0
            )
        },
        getFormattedForecasts() {
            let quotesInTable = _.cloneDeep(this.quotes)
            quotesInTable.forEach(quote => {
                const forecastIndex = this.forecast.findIndex(
                    forecast => forecast.id == quote.id
                )
                const invoiced = this.invoicedPerQuote[quote.id]?.total
                const thisMonth = this.invoicedPerQuote[quote.id]?.thisMonth
                const thisYear = this.invoicedPerQuote[quote.id]?.thisYear
                quote.balanceToBill = quote.totalCosts - (invoiced || 0)
                quote.invoicedThisMonth = thisMonth || 0
                quote.invoicedThisYear = thisYear || 0
                if (forecastIndex > -1) {
                    const forecast = this.forecast[forecastIndex].forecast
                    quote.baseBalanceToBill = this.forecast[
                        forecastIndex
                    ].balanceToBill
                    quote.updatedOn = this.forecast[forecastIndex].updatedOn
                    if (forecast) {
                        this.allDates.forEach(date => {
                            const entry = forecast.find(e => e.date == date)
                            quote[date] = entry
                        })
                    }
                }
            })
            return quotesInTable
        },
        setHeaders() {
            const currentMonth = this.months[new Date().getMonth()]
            const currentYear = new Date().getFullYear()
            const invoicedHeaderText = `INVOICED IN ${currentMonth}`
            const invoicedHeaderTextYear = `INVOICED IN ${currentYear}`
            const vsHeaderText = `ACTUAL VS FORECASTED (${currentMonth})`
            this.originalQuoteHeaders = [
                {
                    text: 'ID',
                    value: 'quoteNumber',
                    align: 'center',
                    sortable: false,
                    class: 'gray-background',
                },
                {
                    text: 'QUOTE NAME',
                    value: 'quoteName',
                    align: 'center',
                    sortable: false,
                    class: 'gray-background',
                    width: 150,
                },
                {
                    text: 'LAST UPDATE',
                    value: 'lastUpdate',
                    align: 'center',
                    sortable: false,
                    class: 'gray-background',
                    width: 150,
                },
                {
                    text: 'QUOTE PRICE',
                    value: 'quotePrice',
                    align: 'center',
                    sortable: false,
                    class: 'gray-background',
                },
                {
                    text: 'CURRENT BALANCE TO BILL',
                    value: 'balanceToBill',
                    align: 'center',
                    sortable: false,
                    class: 'gray-background',
                    width: 140,
                },
                {
                    text: 'BASE BALANCE TO BILL',
                    value: 'baseBalanceToBill',
                    align: 'center',
                    sortable: false,
                    class: 'gray-background',
                    width: 140,
                },
                {
                    text: invoicedHeaderText,
                    value: 'invoicedThisMonth',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                    type: 'month',
                },
                {
                    text: invoicedHeaderTextYear,
                    value: 'invoicedThisYear',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                    type: 'year',
                },
                {
                    text: vsHeaderText,
                    value: 'actualVsForecasted',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                },
                {
                    text: 'FORECASTED PERCENTAGE',
                    value: 'forecastedPercentage',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                },
            ]

            this.forecast.forEach(quote => {
                if (quote.forecast) {
                    quote.forecast.forEach(entry => {
                        this.allDates.push(entry.date)
                    })
                }
            })

            this.dateToShow = [...new Set(this.allDates.sort())]
            const minDate = this.currentDate
            const maxDate = this.dateToShow[this.dateToShow.length - 1]
            this.dateToShow = this.monthsBetweenDates(
                moment(minDate),
                moment(maxDate)
            )

            let yearClasses = {}
            let toggleClass = true

            this.dateToShow.forEach(date => {
                const dateParts = date.split('-')
                const monthIndex = Number(dateParts[1]) - 1
                const year = dateParts[0]
                const dateText = `${this.months[monthIndex]}-${year}`

                if (!yearClasses[year]) {
                    yearClasses[year] = toggleClass
                        ? 'dark-gray-background'
                        : 'gray-background'
                    toggleClass = !toggleClass
                }
                this.originalQuoteHeaders.push({
                    text: dateText,
                    value: 'date',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    type: 'month',
                    month: date,
                    class: yearClasses[year],
                })
            })

            this.dateToShow.forEach(date => {
                const dateParts = date.split('-')
                const year = dateParts[0]
                const existent = this.originalQuoteHeaders.find(
                    h => h.text == year
                )
                if (!existent) {
                    this.originalQuoteHeaders.push({
                        text: year,
                        value: 'dateYear',
                        align: 'center',
                        sortable: false,
                        width: 100,
                        type: 'year',
                        class: yearClasses[year],
                        year,
                    })
                }
            })
            this.originalQuoteHeaders.push({
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
                class: 'fixed-column gray-background',
            })
        },
        getInsights() {
            const project = this.project
            this.insights[0].value = this.formatCurrency(
                project.currency,
                project.price
            )
            this.insights[1].value = project.totalInvoiced
                ? this.formatCurrency(
                      project.currency,
                      Number(project.totalInvoiced)
                  )
                : '-'
            this.insights[2].value = this.formatCurrency(
                project.currency,
                project.price - (project.totalInvoiced || 0)
            )
            const totalPercentage = this.calculateTotalPercentage()
            this.insights[3].value = totalPercentage + '%'
            if (totalPercentage == 100) {
                this.insights[3].color = '#2f9a6b'
            } else if (totalPercentage > 100) {
                this.insights[3].color = '#cb3c33'
            } else {
                this.insights[3].color = '#d86514'
            }
        },
        formatCurrency(currency, value) {
            if (currency && value) {
                return new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: currency,
                    maximumFractionDigits: 0,
                }).format(Number(value))
            }
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('MMM DD YYYY')}`
        },
        formatDate2(seconds) {
            return `${moment.unix(seconds).format('YYYY-MM')}`
        },
        closeHistoryView() {
            this.historyView = false
        },
        openReplaceForecast(quote) {
            this.showMonths()
            this.quoteInEdition = quote.id
            this.calculateForecastValues(quote)
            this.selectedForecast = _.cloneDeep(quote)
        },
        calculateForecastValues(quote) {
            const forecastIndex = this.forecast.findIndex(
                q => q.id == this.quoteInEdition
            )
            this.originalForecast = _.cloneDeep(this.forecast)
            if (forecastIndex > -1) {
                this.forecast[forecastIndex].balanceToBill = quote.balanceToBill
                if (this.forecast[forecastIndex].forecast) {
                    this.forecast[forecastIndex].forecast.forEach(entry => {
                        entry.editable = entry.date != this.currentDate
                        entry.percentage =
                            (Number(entry.amount) * 100) / quote.balanceToBill
                    })
                }
            } else {
                this.forecast.push({
                    id: quote.id,
                    balanceToBill: quote.balanceToBill,
                    updatedOn: {
                        _seconds: Math.floor(Date.now() / 1000),
                        _nanoseconds: (Date.now() % 1000) * 1e6,
                    },
                    forecast: [],
                })
            }
        },
        closeReplaceForecast() {
            this.quoteInEdition = undefined
            this.selectedForecast = undefined
            this.forecast = _.cloneDeep(this.originalForecast)
            this.getInsights()
        },
        async replaceForecast() {
            try {
                this.loading = true
                this.quoteInEdition = undefined
                this.forecast.forEach(quote => {
                    quote.forecast.forEach(entry => {
                        delete entry.editable
                        entry.balanceToBill == this.forecast.balanceToBill
                    })
                })
                await API.replaceForecast({
                    projectId: this.project.id,
                    forecast: this.forecast,
                })
                this.getInsights()
                this.loading = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        showMonths() {
            this.quoteHeaders = this.originalQuoteHeaders.filter(
                h => h.type != 'year'
            )
            this.viewType = 'month'
        },
        showYears() {
            this.quoteHeaders = this.originalQuoteHeaders.filter(
                h => h.type != 'month'
            )
            this.viewType = 'year'
        },
        monthsBetweenDates(startDate, endDate) {
            let datesObject = []
            let currentDay = startDate
            let dates = []
            while (currentDay.isSameOrBefore(endDate)) {
                dates.push(currentDay.format('YYYY-MM'))
                datesObject[currentDay.format('YYYY-MM')] = 0
                currentDay.add(1, 'month')
            }
            return dates
        },
        calculateTotalPercentage() {
            let total = 0
            let totalB2B = 0
            this.forecast.forEach(quote => {
                totalB2B += quote.balanceToBill
                if (quote.forecast) {
                    quote.forecast.forEach(entry => {
                        if (
                            moment(entry.date).isSameOrAfter(this.currentDate)
                        ) {
                            total += Number(entry.amount)
                        }
                    })
                }
            })
            return totalB2B > 0 ? ((total * 100) / totalB2B).toFixed(1) : 0
        },
        calculateForecastedPercentage(quote) {
            let total = 0
            const forecastIndex = this.forecast.findIndex(
                forecast => forecast.id == quote.id
            )
            if (forecastIndex > -1) {
                const forecast = this.forecast[forecastIndex].forecast
                if (forecast) {
                    total = forecast.reduce(
                        (accumulator, entry) =>
                            accumulator +
                            (entry.amount &&
                            moment(entry.date).isSameOrAfter(this.currentDate)
                                ? Number(entry.amount)
                                : 0),
                        0
                    )
                }
                return (
                    (total * 100) /
                    this.forecast[forecastIndex].balanceToBill
                ).toFixed(1)
            } else {
                return 0
            }
        },
        totalPerDate(date) {
            let resultPerDate = 0

            this.forecast.forEach(quote => {
                if (quote.forecast) {
                    quote.forecast.forEach(entry => {
                        if (date.includes('-')) {
                            if (entry.date == date) {
                                resultPerDate += Number(entry.amount)
                            }
                        } else {
                            if (entry.date.includes(date)) {
                                resultPerDate += moment(
                                    entry.date
                                ).isSameOrAfter(this.currentDate)
                                    ? Number(entry.amount)
                                    : 0
                            }
                        }
                    })
                }
            })
            const amount = this.formatCurrency(
                this.project.currency,
                resultPerDate.toFixed(1)
            )
            const percentage = (resultPerDate * 100) / this.totalBaseB2B() || 0
            return percentage.toFixed(1) + '%' + ' ' + amount
        },
        openAddNewMonth() {
            this.createDialog = true
            this.originalEntry = {}
            this.newMonthForm = true
        },
        openEditMonth(entry, date) {
            this.originalEntry = entry
                ? entry
                : { date, amount: 0, percentage: 0 }
            this.createDialog = false
            this.newMonthForm = true
        },
        addEntry(newEntry) {
            newEntry.editable = true
            const forecastIndex = this.forecast.findIndex(
                quote => quote.id == this.quoteInEdition
            )
            if (forecastIndex > -1) {
                this.forecast[forecastIndex].updatedOn = {
                    _seconds: Math.floor(Date.now() / 1000),
                    _nanoseconds: (Date.now() % 1000) * 1e6,
                }

                this.forecast[forecastIndex].forecast.push(newEntry)
                this.setHeaders()
                this.showMonths()
            }
            this.getInsights()
        },
        replaceEntry(entry) {
            const forecastIndex = this.forecast.findIndex(
                quote => quote.id == this.quoteInEdition
            )

            if (forecastIndex > -1) {
                this.forecast[forecastIndex].updatedOn = {
                    _seconds: Math.floor(Date.now() / 1000),
                    _nanoseconds: (Date.now() % 1000) * 1e6,
                }
                const entryIndex = this.forecast[
                    forecastIndex
                ].forecast.findIndex(e => entry.date == e.date)

                if (entryIndex > -1) {
                    this.forecast[forecastIndex].forecast[entryIndex] = entry
                }
            }
            this.getInsights()
        },
        openHistory(quote) {
            this.historyQuote = quote
            this.historyView = true
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow-y: scroll;
}

.text-orange {
    color: orange;
    font-weight: bold;
}
.text-green {
    color: green;
    font-weight: bold;
}
.text-red {
    color: red;
    font-weight: bold;
}

.fixed-column {
    position: sticky;
    right: 0;
    z-index: 1;
}

.gray-background {
    background-color: #eee;
}

.white-background {
    background-color: white;
}

.dark-gray-background {
    background-color: #c3c3c3;
}
</style>
